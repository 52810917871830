import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  BreadCrumbs,
  GlobalRoleType,
  Header,
  Loader,
  SeparatorComponent,
  useAuthStore,
} from '@exertis/template';

import { Grid } from '@mui/material';

import {
  DeleteAdvertisement,
  GetAdvertisementList,
} from '../hooks/advertisementHooks';
import { GlobalRoleList } from '../hooks/accessHooks';
import AdvertisementFilter from '../components/advertisement/AdvertisementFilter';
import AdvertisementList from '../components/advertisement/AdvertisementList';

type Props = {
  isActive: boolean;
};

const Advertisement = (props: Props) => {
  //Translation
  const { t } = useTranslation();

  const { me } = useAuthStore();

  /**
   * Init props
   */
  const { isActive } = props;

  /**
   * Global states
   */
  const [paginationPage, setPaginationPage] = useState(1);
  const [globalRoleValues, setGlobalRoleValues] = useState<GlobalRoleType[]>(
    []
  );
  const [destinationValues, setDestinationValues] = useState<string[]>([]);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const handleDeleteSuccess = () => {
    setIsSuccessModalVisible(true);
  };

  /**
   * Advertisement Api calls
   */
  const { isInitialLoading, data, refetch } = GetAdvertisementList(
    paginationPage,
    globalRoleValues,
    destinationValues,
    isActive
  );
  const { data: globalRole } = GlobalRoleList();
  const { mutate: mutateDel } = DeleteAdvertisement(handleDeleteSuccess);

  /**
   * Get QueryClient from the context
   */
  const queryClient = useQueryClient();

  /**
   * Get language from local storage
   */
  const language = localStorage.getItem('language');

  /**
   * Handle language change
   */
  useEffect(() => {
    if (language) {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ['globalRole'],
        });
      }, 1000);
    }
    // eslint-disable-next-line
  }, [language]);

  /**
   * Init breadCrumb items
   */
  const routeItems = [
    {
      path: '/active',
      links: [
        {
          title: t('advertisement:header.breadCrumbs_first_child'),
          href: '/',
        },
        {
          title: t('advertisement:header.breadCrumbs_second_child_active'),
          href: '/active',
        },
      ],
    },
    {
      path: '/inactive',
      links: [
        {
          title: t('advertisement:header.breadCrumbs_first_child'),
          href: '/',
        },
        {
          title: t('advertisement:header.breadCrumbs_second_child_inactive'),
          href: '/inactive',
        },
      ],
    },
  ];

  /**
   * Return TSX content
   */
  return (
    <>
      <Header
        welcome={t('advertisement:header.welcome')}
        username={me?.firstName || ''}
        titlePage={
          isActive
            ? t('advertisement:header.titleActive')
            : t('advertisement:header.titleInActive')
        }
        breadCrumbs={BreadCrumbs(routeItems)}
      />
      <SeparatorComponent />
      <Grid container item xs={12}>
        <AdvertisementFilter
          globalRole={globalRole?.data}
          setGlobalRoleValues={setGlobalRoleValues}
          setDestinationValues={setDestinationValues}
          isActive={isActive}
        />
        {isInitialLoading ? (
          <Loader />
        ) : (
          <AdvertisementList
            data={data}
            paginationPage={paginationPage}
            setPaginationPage={setPaginationPage}
            globalRole={globalRole?.data}
            refetch={refetch}
            deleteAdvertisement={mutateDel}
            isSuccessModalVisible={isSuccessModalVisible}
            setIsSuccessModalVisible={setIsSuccessModalVisible}
          />
        )}
      </Grid>
    </>
  );
};

export default Advertisement;
