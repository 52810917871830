import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BlackTypography,
  FilterTypography,
  GlobalRoleType,
} from '@exertis/template';

import {
  Grid,
  FormControl as MuiFormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/system';

import { destinations } from '../../constants/destinations';

/**
 * Styled components
 */
const FormControl = styled(MuiFormControl)({
  margin: '8px 8px 8px 0px',
});

type Props = {
  globalRole: GlobalRoleType[] | undefined;
  setGlobalRoleValues(globalRoleValues: GlobalRoleType[]): void;
  setDestinationValues(destinationValues: string[]): void;
  isActive: boolean;
};

const AdvertisementFilter = (props: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Init props
   */
  const { globalRole, setGlobalRoleValues, setDestinationValues, isActive } =
    props;

  /**
   * States
   */
  const [filterByRole, setFilterByRole] = useState<GlobalRoleType[]>([]);
  const [filterByDestination, setFilterByDestination] = useState<string[]>([]);

  const handleChangeDestinationCheckbox = (event: any) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved: string[] = [];

    value.forEach((item: string) => {
      if (duplicateRemoved.findIndex((o: string) => o === item) >= 0) {
        if (duplicateRemoved.length === 1) {
          duplicateRemoved.pop();
        } else {
          duplicateRemoved = duplicateRemoved.filter((x: string) => x === item);
        }
      } else {
        duplicateRemoved.push(item);
      }
    });
    setFilterByDestination(duplicateRemoved);
  };

  const handleChangeRoleCheckbox = (event: any) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved: GlobalRoleType[] = [];

    console.log(duplicateRemoved);

    value.forEach((item: any) => {
      if (
        duplicateRemoved.findIndex((o: GlobalRoleType) => o.id === item.id) >= 0
      ) {
        if (duplicateRemoved.length === 1) {
          duplicateRemoved.pop();
        } else {
          duplicateRemoved = duplicateRemoved.filter(
            (x: GlobalRoleType) => x.id === item.id
          );
        }
      } else {
        duplicateRemoved.push(item);
      }
    });
    setFilterByRole(duplicateRemoved);
  };

  const handleLabelTranslation = (label: string) => {
    switch (label) {
      case 'clients':
        return t('common:globalRoles.clients');
      case 'collaborateurs':
        return t('common:globalRoles.collaborateurs');
      case 'fournisseurs':
        return t('common:globalRoles.fournisseurs');
      default:
        return '';
    }
  };

  /**
   * Initiate filters
   */
  useEffect(() => {
    setGlobalRoleValues([]);
    setDestinationValues([]);
    setFilterByRole([]);
    setFilterByDestination([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  /**
   * Return TSX content
   */
  return (
    <>
      <Grid container sx={{ mb: { xs: 12, md: 12, lg: 12 } }}>
        <Grid item xs={10} md={12} lg={12}>
          <Grid container rowSpacing={5}>
            <Grid container item xs={12} md={4} lg={4} alignItems="center">
              <BlackTypography style={{ marginRight: 25 }}>
                {t('advertisement:advertisementFilter.labelGlobalRole')}
              </BlackTypography>
              <FormControl
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '80%',
                    lg: '300px',
                  },
                }}
              >
                <Select
                  name="globalRole"
                  multiple
                  value={filterByRole}
                  onChange={handleChangeRoleCheckbox}
                  style={{ height: 36 }}
                  renderValue={(selected) =>
                    selected
                      ?.map((x: any) =>
                        handleLabelTranslation(x.globalRoleName)
                      )
                      .join(', ')
                  }
                >
                  {globalRole?.map((role: any, key: number) => {
                    return (
                      <MenuItem key={role.id} value={role}>
                        <Checkbox
                          checked={
                            filterByRole &&
                            filterByRole.findIndex(
                              (item: any) => item.id === role.id
                            ) >= 0
                          }
                        />
                        <ListItemText primary={role.displayName} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={12} md={4} lg={4} alignItems="center">
              <BlackTypography style={{ marginRight: 25 }}>
                {t('advertisement:advertisementFilter.labelDestination')}
              </BlackTypography>
              <FormControl
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '80%',
                    lg: '300px',
                  },
                }}
              >
                <Select
                  name="destination"
                  multiple
                  onChange={handleChangeDestinationCheckbox}
                  value={filterByDestination}
                  style={{ height: 36 }}
                  renderValue={(selected) =>
                    selected?.map((x: string) => x).join(', ')
                  }
                >
                  {destinations.map((destination: string, index) => {
                    return (
                      <MenuItem key={index} value={destination}>
                        <Checkbox
                          checked={
                            filterByDestination &&
                            filterByDestination?.findIndex(
                              (item: string) => item === destination
                            ) >= 0
                          }
                        />
                        <ListItemText primary={destination} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={12} md={3} lg={3} alignItems="center">
              <FilterTypography
                onClick={() => {
                  setGlobalRoleValues(filterByRole);
                  setDestinationValues(filterByDestination);
                }}
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '80%',
                    lg: '300px',
                  },
                }}
              >
                {t('advertisement:advertisementFilter.applyFilter')}
              </FilterTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AdvertisementFilter;
