import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@mui/material';

import { Advertisement } from '../../types/advertisementTypes';


type Props = {
  anchorEl: any;
  advertisementToActOn: Advertisement | null;
  handleCloseMenu(): void;
  setTags(tags: string): void;
  showModal(): void;
  showConfirmationModal(): void;
};

const MenuItemsComponent = (props: Props) => {
  //Translation
  const { t } = useTranslation();

  //Init props
  const { anchorEl, advertisementToActOn, handleCloseMenu, setTags, showModal, showConfirmationModal } = props;

  /**
   * Return TSX content
   */
  return (
    <>
      <Menu
        key={advertisementToActOn?.id}
        sx={{
          borderRadius: 4,
          width: 220,
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            setTags('editAd');
            showModal();
          }}
        >
          {t('advertisement:advertisementList.updateAd')}
        </MenuItem>
        <MenuItem
        >
          <a href={advertisementToActOn?.pageUrl} target="_blank" rel="noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {t('advertisement:advertisementList.viewAd')}
          </a>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setTags('deleteAd');
            showConfirmationModal();
          }}
        >
          {t('advertisement:advertisementList.deleteAd')}
        </MenuItem>
      </Menu>
    </>
  )
}

export default MenuItemsComponent;
