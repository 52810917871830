import { useTranslation } from 'react-i18next';
import { useController, UseControllerProps } from 'react-hook-form';
import { FormHelperErrorText } from '@exertis/template';

import { Button as MuiButton, FormLabel, Grid, TextField } from '@mui/material';
import { styled } from '@mui/system';

interface Props extends UseControllerProps {
  label?: string;
  displayTextError?: boolean;
  pictureToShow?: any;
  onChangeEvent(event: any): void;
}

const FormControlTextField = styled(TextField)({
  display: 'none',
});

const UploadButton = styled(MuiButton)({
  borderColor: '#fff',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
  marginRight: 20,
  '&:hover': {
    border: '#fff',
  },
  width: 119,
  height: 36,
});

const StyledGridCard = styled(Grid)({
  userSelect: 'none',
  width: '100%',
  marginTop: 10,
  border: '1px dashed #9E9E9E',
  borderRadius: 4,
  background: 'transparent',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
});

const Picture = styled('img')({
  height: '110px',
});

const PlaceholderPicture = styled('img')({
  width: '50%',
  height: '50%',
});

const FormControlInputFile = ({
  displayTextError = true,
  onChangeEvent,
  pictureToShow,
  ...props
}: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();
  const { field, fieldState } = useController(props);

  return (
    <>
      {props.label && <FormLabel required>{props.label}</FormLabel>}
      <Grid container alignItems={'center'} justifyContent={'center'}>
        <Grid item xs={12} sm={12} lg={12}>
          <StyledGridCard>
            {pictureToShow ? (
              <Picture src={pictureToShow} alt="preview image" />
            ) : (
              <PlaceholderPicture src={pictureToShow} alt="default image" />
            )}
          </StyledGridCard>
        </Grid>
        <Grid item xs={12} sm={2} lg={4}>
          <UploadButton
            variant="outlined"
            color="primary"
            sx={{
              ml: { xs: 0, md: 0, lg: 5 },
              mt: { xs: 5, md: 0, lg: 0 },
            }}
          >
            <FormLabel
              htmlFor="filePicker"
              style={{ textTransform: 'uppercase' }}
            >
              {t('advertisement:advertisementList.formModal.buttonAddPicture')}
            </FormLabel>
          </UploadButton>
        </Grid>
      </Grid>
      <FormControlTextField
        {...field}
        id="filePicker"
        type={'file'}
        onChange={onChangeEvent}
      />
      {displayTextError && fieldState.error && (
        <FormHelperErrorText>{fieldState.error.message}</FormHelperErrorText>
      )}
    </>
  );
};
export default FormControlInputFile;
