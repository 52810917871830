import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ConfirmationModal,
  GlobalRoleType,
  moreTableIcon,
  PrimaryButton,
  SuccessModal,
} from '@exertis/template';

import {
  Grid,
  Typography as MuiTypography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Pagination as MuiPagination,
  IconButton,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled, spacing } from '@mui/system';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import AddIcon from '@mui/icons-material/Add';

import {
  Advertisement,
  ApiAdvertisementResponse,
} from '../../types/advertisementTypes';
import AdvertisementFormModal from './AdvertisementFormModal';
import ImageModal from './ImageModal';
import MenuItemsComponent from './MenuItemsComponent';

const ColumnHeaderTypography = styled(MuiTypography)({
  fontWeight: '550',
  color: '#9E9E9E',
  fontSize: 14,
  lineHeight: 5,
});

const Typography = styled(MuiTypography)(spacing);

const Pagination = styled(MuiPagination)(spacing);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderRight: '0.5px solid #E0E0E0',
    borderBottom: '0',
    padding: '0px 0px 0px 10px',
    height: '30px',
    '&:nth-of-type(1)': {
      borderRight: 0,
    },
  },
  [`&.${tableCellClasses.body}`]: {
    borderRight: '0.5px solid #E0E0E0',
    borderBottom: '0',
    padding: '10px',
    '&:nth-of-type(1)': {
      padding: '0px 0px 0px 0px',
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const IconMoreAction = styled('img')({
  width: 15,
  height: 15,
});

type Props = {
  data: ApiAdvertisementResponse | undefined;
  paginationPage: number;
  setPaginationPage(page: number): void;
  globalRole: GlobalRoleType[] | undefined;
  refetch(): void;
  deleteAdvertisement?: any;
  isSuccessModalVisible: boolean;
  setIsSuccessModalVisible(isSuccessModalVisible: boolean): void;
};

const AdvertisementList = (props: Props) => {
  //Translation
  const { t } = useTranslation();

  /**
   * Init props
   */
  const {
    data,
    paginationPage,
    setPaginationPage,
    globalRole,
    refetch,
    deleteAdvertisement,
    isSuccessModalVisible,
    setIsSuccessModalVisible,
  } = props;
  /**
   * States of component
   */
  const [advertisementToActOn, setAdvertisementToActOn] =
    useState<Advertisement | null>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tags, setTags] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [pictureToShow, setPictureToShow] = useState<any>(null);

  /**
   * Open action menu for specific row
   */
  const handleOpenMenu = (event: any, list: Advertisement) => {
    setAnchorEl(event?.currentTarget);
    setAdvertisementToActOn(list);
  };
  /**
   * Close action menu for specific row
   */
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  /**
   * Handle change page pagination
   */
  const handleChangePage = (_: any, value: any) => {
    setPaginationPage(value);
  };

  /**
   * Action to do after doing action confirmation
   * @param confirmation
   */
  const handleModalConfirmation = async (confirmation: boolean) => {
    if (confirmation && tags === 'deleteAd') {
      deleteAdvertisement(advertisementToActOn?.id);
      setAdvertisementToActOn(null);
    }
  };

  /**
   * Handle language change
   */
  const handleLabelTranslation = (label: string) => {
    switch (label) {
      case 'clients':
        return t('common:globalRoles.clients');
      case 'collaborateurs':
        return t('common:globalRoles.collaborateurs');
      case 'fournisseurs':
        return t('common:globalRoles.fournisseurs');
      default:
        return '';
    }
  };

  /**
   * Format date to string
   */
  const getFormattedDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  /**
   * Show Modal
   */
  const showModal = () => {
    setIsModalVisible(true);
    setAnchorEl(null);
  };
  const showConfirmationModal = () => {
    setIsConfirmationModalVisible(true);
    setAnchorEl(null);
  };

  const hideModal = () => {
    setTags('');
    setIsModalVisible(false);
    setIsConfirmationModalVisible(false);
    setIsSuccessModalVisible(false);
    setPictureToShow(null);
  };

  /**
   * Return TSX content
   */
  return (
    <>
      <ConfirmationModal
        openModal={isConfirmationModalVisible}
        title={
          tags === 'deleteAd'
            ? t(
                'advertisement:advertisementList.modals.confirmationDelete.title'
              )
            : null
        }
        description={
          tags === 'deleteAd'
            ? t(
                'advertisement:advertisementList.modals.confirmationDelete.description'
              )
            : null
        }
        titleButton={
          tags === 'deleteAd'
            ? t(
                'advertisement:advertisementList.modals.confirmationDelete.confirmationButton'
              )
            : null
        }
        cancelButton={t('common:cancel')}
        handleModalConfirmation={handleModalConfirmation}
        hideDialog={hideModal}
      />
      <SuccessModal
        openModal={isSuccessModalVisible}
        title={
          tags === 'deleteAd'
            ? t('advertisement:advertisementList.modals.successDelete.title')
            : null
        }
        description={
          tags === 'deleteAd'
            ? t(
                'advertisement:advertisementList.modals.successDelete.description'
              )
            : null
        }
        cancelButton={t('common:cancel')}
        hideSuccessModal={hideModal}
      />
      <AdvertisementFormModal
        openModal={isModalVisible}
        tag={tags}
        setTags={setTags}
        hideModal={hideModal}
        globalRole={globalRole}
        refetch={refetch}
        advertisementToActOn={tags === 'editAd' ? advertisementToActOn : null}
        setAdvertisementToActOn={setAdvertisementToActOn}
      />
      <ImageModal
        openModal={pictureToShow ? true : false}
        advertisement={pictureToShow}
        hideModal={hideModal}
      />
      <Grid container item xs={12}>
        <Grid container item xs={12} justifyContent="center">
          {data && data.data.length !== 0 ? (
            <>
              <TableContainer component={Paper} style={{ width: '100%' }}>
                <Table
                  aria-label="simple table"
                  style={{ overflowY: 'hidden' }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>
                        <ColumnHeaderTypography>
                          {t(
                            'advertisement:advertisementList.headerTable.image'
                          )}
                        </ColumnHeaderTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <ColumnHeaderTypography>
                          {t(
                            'advertisement:advertisementList.headerTable.adtitle'
                          )}
                        </ColumnHeaderTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <ColumnHeaderTypography>
                          {t(
                            'advertisement:advertisementList.headerTable.destination'
                          )}
                        </ColumnHeaderTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <ColumnHeaderTypography>
                          {t(
                            'advertisement:advertisementList.headerTable.globalRole'
                          )}
                        </ColumnHeaderTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <ColumnHeaderTypography>
                          {t(
                            'advertisement:advertisementList.headerTable.startDate'
                          )}
                        </ColumnHeaderTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <ColumnHeaderTypography>
                          {t(
                            'advertisement:advertisementList.headerTable.endDate'
                          )}
                        </ColumnHeaderTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <ColumnHeaderTypography>
                          {t(
                            'advertisement:advertisementList.headerTable.stats'
                          )}
                        </ColumnHeaderTypography>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.data.map((item: Advertisement, index: number) => (
                      <StyledTableRow key={item.id}>
                        <StyledTableCell align="center">
                          <IconButton
                            onClick={(e: any) => handleOpenMenu(e, item)}
                          >
                            <IconMoreAction src={moreTableIcon()} />
                          </IconButton>
                          <MenuItemsComponent
                            anchorEl={anchorEl}
                            advertisementToActOn={advertisementToActOn}
                            handleCloseMenu={handleCloseMenu}
                            setTags={setTags}
                            showModal={showModal}
                            showConfirmationModal={showConfirmationModal}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <img
                            src={item.thumbnailUrl}
                            alt=""
                            onClick={() => {
                              setPictureToShow(item);
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell width="30%">
                          <Typography>{item.advertisementName}</Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography>
                            {item.advertisementDestinations
                              ?.map((destination) => destination)
                              .join(', ')}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography>
                            {item.advertisementGlobalRoles
                              ?.map((role) =>
                                handleLabelTranslation(role.globalRoleName)
                              )
                              .join(', ')}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography>
                            {getFormattedDate(item.startDate)}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography>
                            {item.dateEnd !== null
                              ? getFormattedDate(item.dateEnd)
                              : ''}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Grid container wrap="nowrap">
                            <Grid container item wrap="nowrap">
                              <Grid>
                                <Typography>{item.totalViews}</Typography>{' '}
                              </Grid>
                              <Grid item xs={1}></Grid>
                              <Grid>
                                <VisibilityIcon
                                  color="disabled"
                                  fontSize="small"
                                />
                              </Grid>
                            </Grid>
                            <Grid container item wrap="nowrap">
                              <Grid>
                                <Typography>{item.totalClicks}</Typography>
                              </Grid>
                              <Grid item xs={1}></Grid>
                              <Grid>
                                <TouchAppIcon
                                  color="disabled"
                                  fontSize="small"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid
                container
                justifyContent="flex-end"
                style={{ width: '100%' }}
              >
                <Pagination
                  mt={5}
                  mb={5}
                  count={Math.ceil(Number(data?.meta.total) / 50)}
                  page={paginationPage}
                  onChange={handleChangePage}
                  color="primary"
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid container justifyContent={'center'} sx={{ mt: 15 }}>
                <Typography variant="h6" gutterBottom display="inline">
                  {t('advertisement:advertisementList.emptyTable')}
                </Typography>
              </Grid>
            </>
          )}
          <Grid
            container
            justifyContent="center"
            style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
          >
            <PrimaryButton
              type="submit"
              onClick={() => {
                setTags('createAd');
                showModal();
              }}
              startIcon={<AddIcon color="inherit" />}
              typography={t('advertisement:advertisementList.addNewAd')}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AdvertisementList;
