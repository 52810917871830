import { useTranslation } from 'react-i18next';
import { useQuery, UseMutationResult } from '@tanstack/react-query';
import {
  ApiError,
  ApiGlobalRoleResponse,
  ApiType,
  HeaderAppByRole,
  httpApi,
  ListAccessessType,
  Toast,
  useAppMutation,
} from '@exertis/template';
import { compile } from 'path-to-regexp';

interface GetListAccess {
  data: ListAccessessType[];
  meta: ApiType;
}

/**
 * Get global role list
 */
export function GlobalRoleList() {
  return useQuery(
    ['globalRole'],
    async (): Promise<ApiGlobalRoleResponse> =>
      await httpApi.get(
        `${import.meta.env.VITE_AP_API_BASE_URL}/globalroleparams`
      )
  );
}

/**
 * hook to call api to get list apps by role enabled
 * @param id
 * @returns
 */
export function GetAppsByRoleEnabled(globalRoleName: string, roleName: string) {
  return useQuery(
    ['appsByRoleEnabled', globalRoleName, roleName],
    async (): Promise<HeaderAppByRole> =>
      await httpApi.get(
        `${
          import.meta.env.VITE_AP_API_BASE_URL
        }/appbyroles?${encodeURIComponent(
          'filter[globalRole.globalRoleName][eq]'
        )}=${globalRoleName}&${encodeURIComponent(
          'filter[role.roleName][eq]'
        )}=${roleName}&${encodeURIComponent(
          'filter[app.status][eq]'
        )}=enabled&expand=globalRole,role,app`
      )
  );
}

/**
 * Hook to get user information
 */
export function GetUserByEmail(email: string) {
  return useQuery(
    ['accessLists', email],
    async (): Promise<GetListAccess> =>
      await httpApi.get(
        `${import.meta.env.VITE_AP_API_BASE_URL}/accesses?${encodeURIComponent(
          'filter[email][eq]'
        )}=${email}&expand=globalRole,role&limit=1`
      )
  );
}

/**
 * API to edit rows on list
 * @param id
 * @param data
 * @returns
 */
const editAccess = async (
  id: string,
  data: ListAccessessType
): Promise<ListAccessessType[]> => {
  return await httpApi.patch(
    compile(`${import.meta.env.VITE_AP_API_BASE_URL}/accesses/:id`, {
      encode: encodeURIComponent,
    })({ id }),
    data
  );
};

/**
 * hook to call api edit user
 * @returns
 */
export default function useEditAccess(): UseMutationResult<
  any,
  ApiError,
  any,
  any
> {
  const { t } = useTranslation();
  return useAppMutation(
    ['accessLists', 'accessList'],
    ({ id, data }: { id: string; data: ListAccessessType }) =>
      editAccess(id, data),
    {
      onPostSuccess: () => {
        Toast({
          title: t('common:apiRepsonse.successEdit'),
          method: 'success',
        });
      },
      onPostError: (error: ApiError) => {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}
