import { useTranslation } from 'react-i18next';

import { useQuery, UseMutationResult } from '@tanstack/react-query';
import {
  ApiError,
  GlobalRoleType,
  httpApi,
  Toast,
  useAppMutation,
} from '@exertis/template';

import {
  Advertisement,
  ApiAdvertisementResponse,
} from '../types/advertisementTypes';

/**
 * Get advertisement list with pagination or filters
 * @param paginationPage
 * @param globalRoles
 * @param destinations
 * @returns
 */
export function GetAdvertisementList(
  paginationPage: number,
  globalRoles: GlobalRoleType[],
  destinations: string[],
  isAdActive: boolean
) {
  let filters = handleFilters(globalRoles, destinations);
  let baseUrl = `/pubs?page=${paginationPage}&limit=50&isActive=${
    isAdActive ? '1' : '0'
  }&expand=globalRoles,trackers${filters}`;
  return useQuery(
    ['pubs', paginationPage, globalRoles, destinations, isAdActive],
    async (): Promise<ApiAdvertisementResponse> => await httpApi.get(baseUrl)
  );
}

/**
 * Handle filters
 * @param globalRoles
 * @param destinations
 */
function handleFilters(globalRoles: GlobalRoleType[], destinations: string[]) {
  let filters = '';
  if (globalRoles.length > 0) {
    globalRoles.forEach((role) => {
      filters = filters.concat(`&idGlobalRoles[]=${role.id}`);
    });
  }

  if (destinations.length > 0) {
    destinations.forEach((destination) => {
      filters = filters.concat(`&advertisementDestinations[]=${destination}`);
    });
  }

  return filters;
}

/**
 * Add advertisement hook
 * @returns
 */
export function AddAdvertisement(
  handleCloseModal: any
): UseMutationResult<Advertisement, ApiError> {
  const { t } = useTranslation();
  const response = useAppMutation(
    ['pubs'],
    async (data: any) =>
      await httpApi
        .post('/pubs', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          handleCloseModal();
          Toast({
            title: t('advertisement:advertisementList.apiResponse.adAdded'),
            method: 'success',
          });
        })
        .catch((error: any) => {
          if (error?.message) {
            Toast({
              title: error.message,
              method: 'error',
            });
          }
          if (error?.errors) {
            error?.errors.map((err: any) =>
              Toast({
                title: err.message,
                method: 'error',
              })
            );
          }
        })
  );
  return response;
}

/**
 * Edit Advertisement hook
 * @returns
 */
export function EditAdvertisement(
  handleCloseModal: any
): UseMutationResult<Advertisement, ApiError> {
  const { t } = useTranslation();
  const response = useAppMutation(
    ['pubs'],
    async ({ id, data }: { id: string; data: Advertisement }) =>
      await httpApi
        .post(`/pubs/${id}`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          handleCloseModal();
          Toast({
            title: t('advertisement:advertisementList.apiResponse.adChanged'),
            method: 'success',
          });
        })
        .catch((error: any) => {
          if (error?.message) {
            Toast({
              title: error.message,
              method: 'error',
            });
          }
          if (error?.errors) {
            error?.errors.map((err: any) =>
              Toast({
                title: err.message,
                method: 'error',
              })
            );
          }
        })
  );
  return response;
}

/**
 * Delete Advertisement hook
 * @returns
 */
export function DeleteAdvertisement(
  handleDeleteSuccess: any
): UseMutationResult<Advertisement, ApiError> {
  const { t } = useTranslation();
  const response = useAppMutation(
    ['pubs'],
    async (id: string) => await httpApi.delete(`/pubs/${id}`),
    {
      onPostSuccess: (response: Advertisement) => {
        Toast({
          title: t('advertisement:advertisementList.apiResponse.adDeleted'),
          method: 'success',
        });
        handleDeleteSuccess();
        return response;
      },
      onPostError: (error: ApiError) => {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
  return response;
}

/**
 * Get random advertisement hook
 * @returns
 */
export function GetRandomAdvertisement() {
  return useQuery(
    ['randomPub'],
    async (): Promise<any> => await httpApi.get('/i/pubs')
  );
}
