import { closeIcon } from '@exertis/template';

import { Dialog, Grid } from '@mui/material'
import { styled } from '@mui/system';

import { Advertisement } from '../../types/advertisementTypes';


const StyledDialog = styled(Dialog)({
  '.MuiDialog-paper': {
    background: '#FAFAFA',
    borderRadius: '4px',
    color: '#fffff',
  },
});

const CloseIconModal = styled('img')({
  width: 20,
  height: 20,
  marginTop: 10,
  marginRight: 10,
  zIndex: 1,
});

type Props = {
  openModal: boolean;
  advertisement: Advertisement | undefined;
  hideModal(): void;
};

const ImageModal = (props: Props) => {
  /**
     * Close modal
     */
  const handleCloseModal = () => {
    hideModal();
  };
  /**
  * Init props
  */
  const { openModal, advertisement, hideModal } = props;

  return (
    <StyledDialog
      open={openModal}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          maxHeight: '100%',
          borderRadius: '4px',
        },
      }}
    >
      <Grid container item direction="row-reverse">
        <CloseIconModal
          onClick={handleCloseModal}
          src={closeIcon()}
        />

      </Grid>
      <Grid container justifyContent='center' alignContent='center' >
        <img
          src={advertisement?.imageUrl}
          alt=''
        />
      </Grid>

    </StyledDialog >
  )
}

export default ImageModal;
