import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  closeIcon,
  GlobalRoleType,
  SecondaryButton,
  Toast,
  useAppForm,
} from '@exertis/template';

import { array, object, string } from 'yup';
import { FormProvider } from 'react-hook-form';
import {
  Box,
  CardContent,
  Dialog,
  Grid,
  Typography as MuiTypography,
  FormControl as MuiFormControl,
  MenuItem,
  Select,
  FormLabel,
  TextField,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/system';

import {
  Advertisement,
  AdvertisementGlobalRole,
} from '../../types/advertisementTypes';
import {
  AddAdvertisement,
  EditAdvertisement,
} from '../../hooks/advertisementHooks';
import { imageFormat } from '../../constants/imageFormat';
import FormControlInputFile from './FormControlInputFile';
import { destinations } from '../../constants/destinations';

//Styled component
const StyledDialog = styled(Dialog)({
  '.MuiDialog-paper': {
    background: '#FAFAFA',
    borderRadius: '4px',
    color: '#fffff',
  },
});

const StyledBox = styled(Box)({
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${'/static/img/backgrounds/formModal.svg'})`,
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  left: '57%',
});

const TitleTypography = styled(MuiTypography)((props) => ({
  position: 'absolute',
  width: '100%',
  marginLeft: 'auto',
  marginRight: '15px',
  left: 0,
  right: 0,
  textAlign: 'center',
  color: `${props.theme.palette.primary.main}`,
  fontSize: 48,
}));

const FormControl = styled(MuiFormControl)({
  margin: '8px 8px 8px 0px',
});

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    paddingRight: '10px !important',
  },
  backgroundColor: '#FFFFFF',
});

const CloseIconModal = styled('img')({
  width: 20,
  height: 20,
  marginTop: 10,
  marginRight: 10,
  zIndex: 1,
});

/**
 * Props type
 */
type Props = {
  openModal: boolean;
  tag: string;
  globalRole: GlobalRoleType[] | undefined;
  advertisementToActOn: Advertisement | null;
  setTags(tag: string): void;
  hideModal(): void;
  refetch(): void;
  setAdvertisementToActOn(advertisement: Advertisement | null): void;
};

const AdvertisementFormModal = (props: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Init props
   */
  const {
    openModal,
    tag,
    globalRole,
    advertisementToActOn,
    setTags,
    hideModal,
    refetch,
    setAdvertisementToActOn,
  } = props;

  /**
   * Init states
   */
  const [pictureToShow, setPictureToShow] = useState<any>();
  const [picture, setPicture] = useState<any>(null);
  const [sizePictureError, setSizePictureError] = useState<string | null>(null);
  const [
    advertisementDestinationsCheckbox,
    setAdvertisementDestinationsCheckbox,
  ] = useState<string[] | undefined>([]);
  const [idGlobalRolesCheckbox, setIdGlobalRolesCheckbox] = useState<
    GlobalRoleType[] | undefined
  >([]);
  const [idGlobalRoles, setIdGlobalRoles] = useState<string[]>([]);

  /**
   * Close modal
   */
  const handleCloseModal = () => {
    setTags('');
    setAdvertisementToActOn(null);
    setPictureToShow(null);
    setPicture(null);
    setAdvertisementDestinationsCheckbox([]);
    setIdGlobalRoles([]);
    setIdGlobalRolesCheckbox([]);
    hideModal();
    reset();
  };

  /**
   * Hook to add new advertisement
   */
  const mutateAdd = AddAdvertisement(handleCloseModal);

  /**
   * Hook to edit advertisement
   */
  const mutateEdit = EditAdvertisement(handleCloseModal);

  /**
   * Schema validation form
   */
  const schema = object().shape({
    imageFormat: string()
      .required(
        t('advertisement:advertisementList.formModal.validation.required')
      )
      .test(
        'Check image format',
        t('advertisement:advertisementList.formModal.validation.imageFormat'),
        (value, context) => {
          let advertisementDestinations =
            context.parent.advertisementDestinations;
          if (
            value !== imageFormat[0] &&
            advertisementDestinations &&
            advertisementDestinations.includes(destinations[0])
          ) {
            return false;
          } else {
            return true;
          }
        }
      ),
    advertisementName: string().required(
      t('advertisement:advertisementList.formModal.validation.required')
    ),
    advertisementDestinations: array()
      .of(string())
      .required(
        t('advertisement:advertisementList.formModal.validation.required')
      )
      .min(
        1,
        t('advertisement:advertisementList.formModal.validation.required')
      )
      .nullable(),
    idGlobalRoles: array()
      .required(
        t('advertisement:advertisementList.formModal.validation.required')
      )
      .min(
        1,
        t('advertisement:advertisementList.formModal.validation.required')
      )
      .nullable(),
    pageUrl: string()
      .required(
        t('advertisement:advertisementList.formModal.validation.required')
      )
      .test(
        'Check prefix',
        t('advertisement:advertisementList.formModal.validation.https'),
        (value, context) => {
          return value && value.startsWith('https://') ? true : false;
        }
      ),
    startDate: string().required(
      t('advertisement:advertisementList.formModal.validation.required')
    ),
    dateEnd: string().nullable().optional(),
  });

  /**
   *
   * @param event Handle change picture
   */
  const onPictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setPictureToShow(URL.createObjectURL(event.target.files[0]));
      const sizeOnMb = event.target.files[0].size / 1024 / 1024;

      if (sizeOnMb > 2) {
        setSizePictureError(
          t('advertisement:advertisementList.formModal.validation.pictureSize')
        );
      }
      const formData = new FormData();
      formData.append('imageUrl', event.target.files[0]);
      setPicture(formData);
    }
  };

  /**
   * App form of react hook form
   */
  const appForm = useAppForm(schema);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setValue,
  } = appForm;

  /**
   * Handle submit form
   * @param data
   */
  const onSubmit = (data: any) => {
    if (advertisementToActOn) {
      const dataToEdit: Advertisement = {
        id: advertisementToActOn.id,
        ...data,
        advertisementDestinations: JSON.stringify(
          data.advertisementDestinations
        ),
        idGlobalRoles: JSON.stringify(idGlobalRoles),
        image: picture ? picture.get('imageUrl') : undefined,
        dateEnd: data.dateEnd ? data.dateEnd : undefined,
      };

      mutateEdit.mutateAsync({ id: dataToEdit.id, data: dataToEdit });
    } else {
      const dataToSave: Advertisement = {
        ...data,
        advertisementDestinations: JSON.stringify(
          data.advertisementDestinations
        ),
        idGlobalRoles: JSON.stringify(idGlobalRoles),
        image: picture ? picture.get('imageUrl') : undefined,
        dateEnd: data.dateEnd ? data.dateEnd : undefined,
      };

      if (sizePictureError) {
        Toast({ title: sizePictureError, method: 'error' });
      }
      if (!picture) {
        Toast({
          title: t(
            'advertisement:advertisementList.formModal.validation.imageRequired'
          ),
          method: 'error',
        });
      } else {
        mutateAdd.mutateAsync(dataToSave);
      }
    }
  };

  /**
   * Format date
   */
  const getFormattedDate = (dateString: string) => {
    const newDate = dateString?.substring(0, dateString?.indexOf('T'));
    return newDate;
  };

  const handleChangeDestinationCheckbox = (event: any) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved: string[] = [];

    value.forEach((item: string) => {
      if (duplicateRemoved.findIndex((o: string) => o === item) >= 0) {
        if (duplicateRemoved.length === 1) {
          duplicateRemoved.pop();
        } else {
          duplicateRemoved = duplicateRemoved.filter((x: string) => x === item);
        }
      } else {
        duplicateRemoved.push(item);
      }
    });
    setAdvertisementDestinationsCheckbox(duplicateRemoved);
  };

  const handleChangeRoleCheckbox = (event: any) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved: GlobalRoleType[] = [];

    value.forEach((item: any) => {
      if (
        duplicateRemoved.findIndex((o: GlobalRoleType) => o.id === item.id) >= 0
      ) {
        if (duplicateRemoved.length === 1) {
          duplicateRemoved.pop();
        } else {
          duplicateRemoved = duplicateRemoved.filter(
            (x: GlobalRoleType) => x.id === item.id
          );
        }
      } else {
        duplicateRemoved.push(item);
      }
    });
    setIdGlobalRolesCheckbox(duplicateRemoved);
  };

  /**
   * Handle change language
   */
  const handleLabelTranslation = (label: string) => {
    switch (label) {
      case 'clients':
        return t('common:globalRoles.clients');
      case 'collaborateurs':
        return t('common:globalRoles.collaborateurs');
      case 'fournisseurs':
        return t('common:globalRoles.fournisseurs');
      default:
        return '';
    }
  };

  /**
   * Refetch data after create and update
   */
  useEffect(() => {
    if (mutateAdd.isSuccess || mutateEdit.isSuccess) {
      refetch();
    }
    if (tag === 'createAd') {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mutateAdd.isSuccess,
    mutateAdd.isLoading,
    mutateEdit.isSuccess,
    mutateEdit.isLoading,
    tag,
    refetch,
  ]);

  useEffect(() => {
    reset();
    const roles: GlobalRoleType[] = [];
    const destinations: string[] = [];

    advertisementToActOn?.advertisementDestinations.forEach((item: string) => {
      destinations.push(item);
    });

    advertisementToActOn?.advertisementGlobalRoles.forEach(
      (item: AdvertisementGlobalRole) => {
        const role: GlobalRoleType | undefined = globalRole?.find(
          (element) => element.id === item.idGlobalRole
        );
        if (role) roles.push(role);
      }
    );
    setAdvertisementDestinationsCheckbox(destinations);
    setIdGlobalRolesCheckbox(roles);
    setValue('advertisementDestinations', destinations);
    setValue('idGlobalRoles', roles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advertisementToActOn, reset]);

  useEffect(() => {
    const globalRoleId: string[] = [];
    idGlobalRolesCheckbox?.forEach((item: GlobalRoleType) => {
      globalRoleId.push(item.id);
    });
    setIdGlobalRoles(globalRoleId);
  }, [idGlobalRolesCheckbox]);

  /**
   * Return TSX content
   */
  return (
    <StyledDialog
      open={openModal}
      PaperProps={{
        sx: {
          width: '812px',
          height: '820px',
          borderRadius: '4px',
        },
      }}
    >
      <Grid container item direction="row-reverse">
        <CloseIconModal onClick={handleCloseModal} src={closeIcon()} />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Box
          sx={{
            p: 0,
            height: { xs: '199px', md: '199px', lg: '199px' },
            mt: 0,
          }}
          display="block"
        >
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <StyledBox
                component="div"
                display="block"
                sx={{
                  height: { xs: '199px', md: '199px', lg: '199px' },
                }}
              ></StyledBox>
              <TitleTypography
                variant="h4"
                sx={{
                  mt: { xs: 20, md: 20, lg: 20 },
                }}
              >
                {advertisementToActOn
                  ? t('advertisement:advertisementList.formModal.titleEditAd')
                  : t('advertisement:advertisementList.formModal.title')}
              </TitleTypography>
            </Grid>
          </Grid>
        </Box>
        <CardContent sx={{ px: { xs: 1, md: 5, lg: 15 } }}>
          <FormProvider {...appForm}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid rowSpacing={{ sm: 5, lg: 9 }} container>
                <Grid item xs={12} sm={10} lg={8} sx={{ pt: 0 }}>
                  <FormLabel htmlFor="imageFormat">
                    {t('advertisement:advertisementList.formModal.imageformat')}
                  </FormLabel>
                  <FormControl
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '80%',
                        lg: '100%',
                      },
                    }}
                  >
                    <Select
                      {...register('imageFormat')}
                      defaultValue={advertisementToActOn?.imageFormat || ''}
                      style={{ height: 36 }}
                      error={errors.imageFormat ? true : false}
                    >
                      {imageFormat?.map((item: string, key: number) => {
                        return (
                          <MenuItem key={key} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors.imageFormat && (
                      <span style={{ color: 'red' }}>
                        {errors.imageFormat.message?.toString()}
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <FormControlInputFile
                    name="imageUrl"
                    label={t(
                      'advertisement:advertisementList.formModal.labelPicture'
                    )}
                    pictureToShow={
                      pictureToShow
                        ? pictureToShow
                        : advertisementToActOn &&
                          advertisementToActOn.imageUrl !== null &&
                          tag === 'editAd'
                        ? advertisementToActOn.imageUrl
                        : 'static/img/logo/dragAndDrop.svg'
                    }
                    onChangeEvent={onPictureChange}
                  />
                </Grid>
                <Grid item xs={12} sm={10} lg={8}>
                  <FormLabel htmlFor="destination">
                    {t(
                      'advertisement:advertisementList.formModal.labelInputName'
                    )}
                  </FormLabel>
                  <FormControl
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '80%',
                        lg: '100%',
                      },
                    }}
                  >
                    <StyledTextField
                      {...register('advertisementName')}
                      error={errors.advertisementName ? true : false}
                      defaultValue={advertisementToActOn?.advertisementName}
                    />
                    {errors.advertisementName && (
                      <span style={{ color: 'red' }}>
                        {errors.advertisementName.message?.toString()}
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={10} lg={8} sx={{ pt: 0 }}>
                  <FormLabel htmlFor="destination">
                    {t(
                      'advertisement:advertisementList.formModal.labelDestination'
                    )}
                  </FormLabel>
                  <FormControl
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '80%',
                        lg: '100%',
                      },
                    }}
                  >
                    <Select
                      {...register('advertisementDestinations')}
                      multiple
                      type="array"
                      onChange={handleChangeDestinationCheckbox}
                      value={advertisementDestinationsCheckbox}
                      //defaultValue={advertisementDestinationsCheckbox || []}
                      style={{ height: 36 }}
                      renderValue={(selected) =>
                        selected?.map((x: any) => x).join(', ')
                      }
                      error={errors.advertisementDestinations ? true : false}
                    >
                      {destinations.map((item: string, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            <Checkbox
                              checked={
                                advertisementDestinationsCheckbox &&
                                advertisementDestinationsCheckbox?.findIndex(
                                  (destination: string) => destination === item
                                ) >= 0
                              }
                            />
                            <ListItemText primary={item} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors.advertisementDestinations && (
                      <span style={{ color: 'red' }}>
                        {errors.advertisementDestinations.message?.toString()}
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={10} lg={8} sx={{ pt: 0 }}>
                  <FormLabel htmlFor="globalRole">
                    {t(
                      'advertisement:advertisementList.formModal.labelGlobalRole'
                    )}
                  </FormLabel>
                  <FormControl
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '80%',
                        lg: '100%',
                      },
                    }}
                  >
                    <Select
                      {...register('idGlobalRoles')}
                      multiple
                      type="array"
                      onChange={handleChangeRoleCheckbox}
                      value={idGlobalRolesCheckbox}
                      style={{ height: 36 }}
                      renderValue={(selected) =>
                        selected
                          ?.map((x: any) =>
                            handleLabelTranslation(x.globalRoleName)
                          )
                          .join(', ')
                      }
                      error={errors.idGlobalRoles ? true : false}
                    >
                      {globalRole?.map((role: any, index) => {
                        return (
                          <MenuItem key={role.id} value={role}>
                            <Checkbox
                              checked={
                                idGlobalRolesCheckbox &&
                                idGlobalRolesCheckbox?.findIndex(
                                  (item: GlobalRoleType) => item.id === role.id
                                ) >= 0
                              }
                            />
                            <ListItemText primary={role.displayName} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors.idGlobalRoles && (
                      <span style={{ color: 'red' }}>
                        {errors.idGlobalRoles.message?.toString()}
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={11.5} lg={11.5}>
                  <FormLabel htmlFor="pageUrl">
                    {t('advertisement:advertisementList.formModal.labelUrl')}
                  </FormLabel>
                  <FormControl
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '80%',
                        lg: '100%',
                      },
                    }}
                  >
                    <StyledTextField
                      {...register('pageUrl')}
                      error={errors.pageUrl ? true : false}
                      defaultValue={advertisementToActOn?.pageUrl}
                    />
                    {errors.pageUrl && (
                      <span style={{ color: 'red' }}>
                        {errors.pageUrl.message?.toString()}
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={10} lg={8} sx={{ pt: 0 }}>
                  <FormLabel htmlFor="startDate">
                    {t(
                      'advertisement:advertisementList.formModal.labelstartDate'
                    )}
                  </FormLabel>
                  <FormControl
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '80%',
                        lg: '100%',
                      },
                    }}
                  >
                    <StyledTextField
                      {...register('startDate')}
                      error={errors.startDate ? true : false}
                      name="startDate"
                      type="date"
                      defaultValue={getFormattedDate(
                        advertisementToActOn?.startDate!
                      )}
                      fullWidth
                    />
                    {errors.startDate && (
                      <span style={{ color: 'red' }}>
                        {errors.startDate.message?.toString()}
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={10} lg={8} sx={{ pt: 0 }}>
                  <FormLabel htmlFor="dateEnd">
                    {t(
                      'advertisement:advertisementList.formModal.labelendDate'
                    )}
                  </FormLabel>
                  <FormControl
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '80%',
                        lg: '100%',
                      },
                    }}
                  >
                    <StyledTextField
                      {...register('dateEnd')}
                      name="dateEnd"
                      type="date"
                      defaultValue={getFormattedDate(
                        advertisementToActOn?.dateEnd!
                      )}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Box textAlign="center">
                <SecondaryButton
                  type="submit"
                  variant="contained"
                  typography={t(
                    'advertisement:advertisementList.formModal.submitButton'
                  )}
                />
              </Box>
            </form>
          </FormProvider>
        </CardContent>
      </Grid>
    </StyledDialog>
  );
};

export default AdvertisementFormModal;
