import {
  BackdropLoader,
  BreadCrumbs,
  DashboardProfile,
  DashboardStats,
  Header,
  Loader,
  SeparatorComponent,
  useAuthStore,
} from '@exertis/template';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from '../config';
import { idApp } from '../constants/appConfig';
import useEditAccess from '../hooks/accessHooks';
import { GetRandomAdvertisement } from '../hooks/advertisementHooks';
import { useInitNavbarApps } from '../hooks/app';
import {
  useGetStatisticQueries,
  useGetStatisticSorted,
} from '../hooks/statisticHooks';

const Dashboard = () => {
  const { t } = useTranslation();

  useInitNavbarApps();

  const { me } = useAuthStore();

  /**
   * Global states
   */
  const [statIds, setStatIds] = useState<string[]>([]);
  const [companyNameAfterClick, setCompanyNameAfterClick] = useState<
    string | null
  >(null);
  /**
   * Access api calls
   */
  const { mutateAsync: editRequestProfile, isLoading } = useEditAccess();

  /**
   * Advertisement api calls
   */
  const { data: advertisement } = GetRandomAdvertisement();

  /**
   * Statistic api calls
   */
  const { data: statsList, isInitialLoading: isStatsLoading } =
    useGetStatisticSorted(idApp);
  const result = useGetStatisticQueries(statIds);

  /**
   * Init list of stats to fetch their data
   */
  useEffect(() => {
    if (statsList) {
      setStatIds(statsList.data.map((item: any) => item.id));
    }
  }, [statsList]);

  /**
   * Get company name if exist
   */
  useEffect(() => {
    if (localStorage.getItem('companyName')) {
      setCompanyNameAfterClick(localStorage.getItem('companyName'));
    }
  }, []);

  /**
   * Init breadCrumb items
   */
  const routeItems = [
    {
      path: '/',
      links: [
        { title: t('dashboard:header.breadCrumbs_first_child'), href: '/' },
      ],
    },
  ];

  /**
   * Return TSX content
   */
  return (
    <>
      <Header
        welcome={t('dashboard:header.welcome')}
        username={me?.firstName || ''}
        titlePage={t('dashboard:header.title')}
        breadCrumbs={BreadCrumbs(routeItems)}
      />
      <SeparatorComponent />
      {isStatsLoading ? (
        <BackdropLoader open={isStatsLoading} />
      ) : (
        statsList && (
          <DashboardStats
            stats={statsList?.data}
            statQueries={result}
            advertisement={
              advertisement
                ? {
                    ...advertisement,
                    pubBaseUrl: config.pubSitesApiBaseUrl,
                  }
                : null
            }
          />
        )
      )}
      <SeparatorComponent />
      {!me ? (
        <Loader />
      ) : (
        <DashboardProfile
          editRequestProfile={editRequestProfile}
          isLoading={isLoading}
          companyName={companyNameAfterClick}
        />
      )}
    </>
  );
};
export default Dashboard;
