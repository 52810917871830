import { RouteObject } from 'react-router-dom';
import { NotFound, AccessDenied, LayoutSidebar } from '@exertis/template';

import Dashboard from '../pages/Dashboard';
import Advertisement from '../pages/Advertisement';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <LayoutSidebar />,
    children: [
      {
        path: '/',
        element: <Dashboard />,
      },
      {
        path: '/active',
        element: <Advertisement isActive={true} />,
      },
      {
        path: '/inactive',
        element: <Advertisement isActive={false} />,
      },

    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: 'AccessDenied',
    element: <AccessDenied />,
  },
];

export default routes;
